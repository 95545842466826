import React, { useCallback } from 'react'
import clsx from 'clsx'

import 'modern-normalize/modern-normalize.css'
import './styles.scss'

import reducer, { initialState } from './reducer'

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT
const ACCESS_TOKEN = process.env.REACT_APP_API_ACCESS_TOKEN

interface AppProps {
  path: string
  default: boolean
}

const App: React.FC<AppProps> = () => {
  const [image, setImage] = React.useState<string>()
  const [data, dispatch] = React.useReducer(reducer, initialState)

  const idField = React.useRef<HTMLInputElement>(null)
  const emailField = React.useRef<HTMLInputElement>(null)

  const hasError = !!data.error
  const hasSubmitted = !!data.submitted

  const idFieldClasses = clsx('input__wrapper', {
    'input__wrapper--error': !data.id.valid && data.id.dirty,
  })

  const emailFieldClasses = clsx('input__wrapper', {
    'input__wrapper--error': !data.email.valid && data.email.dirty,
  })

  const getImage = useCallback(() => {
    const imageUrl = '/assets/MiguelOliveira.gif'
    const loadedImage = new Image()
    loadedImage.onload = () => {
      setImage(imageUrl)
    }

    loadedImage.src = imageUrl
  }, [])

  React.useEffect(() => {
    if (!image) {
      getImage()
    }
  })

  const isValidForm = () => {
    const hasValidId = data.id.valid && data.id.dirty
    const hasValidEmail = data.email.valid && data.email.dirty
    return hasValidId && hasValidEmail
  }

  const resetForm = () => {
    dispatch({
      type: 'FORM_RESET',
    })
  }

  const sendForm = async (e: any) => {
    e.preventDefault()

    try {
      await window.fetch(
        `${ENDPOINT}/renders/${data.id.value.toUpperCase()}/recipients`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ACCESS_TOKEN}`,
          },
          body: JSON.stringify({ email: data.email.value }),
        }
      )

      dispatch({ type: 'FORM_SUCCESS' })
    } catch (err) {
      dispatch({ type: 'FORM_ERROR' })
    }
  }

  return (
    <main className="app">
      <div className="wrapper">
        <div className="wrapper__inner">
          {/* Campaign Logo */}
          <div className="wrapper__logo wrapper__logo--top">
            <img src="/assets/meo_logo.svg" alt="Liga-te ao Campeão!" />
          </div>

          <div className="project-name">Miguel Oliveira</div>

          {/* White Top Lines */}
          <div className="wrapper__line wrapper__line--top">
            <div className="wrapper__line--left"></div>
            <div className="wrapper__line--right"></div>
          </div>

          {/* White Inner top lines */}
          <div className="wrapper__line wrapper__line--top--inner">
            <div className="wrapper__line--left"></div>
            <div className="wrapper__line--right"></div>
          </div>

          {/* White Bottom Lines */}
          <div className="wrapper__line wrapper__line--bottom">
            <div className="wrapper__line--left"></div>
            <div className="wrapper__line--right"></div>
          </div>

          {/* White Inner bottom lines */}
          <div className="wrapper__line wrapper__line--bottom--inner">
            <div className="wrapper__line--left"></div>
            <div className="wrapper__line--right"></div>
          </div>

          {/* White Innter left lines  */}
          <div className="wrapper__line wrapper__line--leftInner">
            <div className="wrapper__line--top"></div>
            <div className="wrapper__line--bottom"></div>
          </div>

          {/* White Innter right lines  */}
          <div className="wrapper__line wrapper__line--rightInner">
            <div className="wrapper__line--top"></div>
            <div className="wrapper__line--bottom"></div>
          </div>

          {/* Form Wrapper */}
          <div className="form__wrapper">
            {/* Player Area */}
            <div className="form__player">
              <img
                src="assets/frame_inner.svg"
                alt="Frame Inner"
                aria-hidden="true"
                className="frame-inner"
              />
              {image ? (
                <img src={image} alt="Miguel Oliveira" aria-hidden="true" />
              ) : (
                <img
                  src="/assets/fallback.png"
                  alt="Miguel Oliveira"
                  aria-hidden="true"
                />
              )}
            </div>

            {/* Form */}
            <form className="form__fields" onSubmit={sendForm}>
              {hasSubmitted && (
                <div className="form__feedback">
                  {hasError ? (
                    <React.Fragment>
                      <h1>OOPS!</h1>

                      <p>
                        Infelizmente não conseguimos processar o teu pedido.
                      </p>

                      <p>Por favor tenta novamente</p>

                      <button
                        className="button button--back"
                        onClick={resetForm}
                      >
                        Voltar &gt;
                      </button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h1>Email Enviado</h1>

                      <p>
                        Irás receber um email para{' '}
                        <strong>{data.email.value}</strong> com a tua
                        fotografia.
                      </p>

                      <p>Obrigado por te ligares ao campeão.</p>

                      <button
                        className="button button--back"
                        onClick={resetForm}
                      >
                        Voltar &gt;
                      </button>
                    </React.Fragment>
                  )}
                </div>
              )}

              {!hasSubmitted && (
                <React.Fragment>
                  <div className="form__fields__inner">
                    <p className="form__text">
                      Insere o teu id e e-mail para enviarmos a tua fotografia.
                    </p>

                    <div className={idFieldClasses}>
                      <label className="input__label" htmlFor="form-id">
                        ID
                      </label>
                      <input
                        autoComplete="off"
                        ref={idField}
                        tabIndex={1}
                        id="form-id"
                        placeholder="ID"
                        className="input"
                        type="text"
                        value={data.id.value}
                        pattern="^[a-zA-Z0-9]{6,6}$"
                        onChange={(e: any) =>
                          dispatch({
                            type: 'UPDATE_ID',
                            payload: {
                              value: e.target.value,
                              valid: idField.current?.validity.valid || false,
                            },
                          })
                        }
                      />

                      {data.id.dirty && !data.id.valid && (
                        <small className="input__message">
                          Por favor insere um id válido
                        </small>
                      )}
                    </div>

                    <div className={emailFieldClasses}>
                      <label className="input__label" htmlFor="form-email">
                        Email
                      </label>

                      <input
                        autoComplete="off"
                        ref={emailField}
                        tabIndex={2}
                        id="form-email"
                        placeholder="Email"
                        className="input"
                        type="email"
                        value={data.email.value}
                        onChange={(e: any) =>
                          dispatch({
                            type: 'UPDATE_EMAIL',
                            payload: {
                              value: e.target.value,
                              valid:
                                emailField.current?.validity.valid || false,
                            },
                          })
                        }
                      />

                      {data.email.dirty && !data.email.valid && (
                        <small className="input__message">
                          Por favor insere um email válido
                        </small>
                      )}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="button button--send"
                    tabIndex={3}
                    onClick={sendForm}
                    disabled={!isValidForm()}
                  >
                    <span>Enviar &gt;</span>
                  </button>
                </React.Fragment>
              )}
            </form>
          </div>
        </div>
        <a
          href="https://7egend.cr"
          rel="noreferrer noopener"
          target="_blank"
          className="copyright"
        >
          <img
            width="100"
            height="auto"
            src="/assets/7egend.svg"
            alt="Powered By 7egend - https://7egend.cr"
          />
        </a>
      </div>
    </main>
  )
}

export default App
