export interface FormField {
  value: string;
  valid: boolean | null;
  dirty: boolean;
}

export interface FormAction {
  type: string;
  payload?: any;
}

export interface FormState {
  id: FormField;
  email: FormField;
  valid: boolean;
  error: boolean;
  submitted: boolean;
}

export const ID_REGEX = new RegExp("^[a-zA-Z0-9]{6,6}$");
const SEARCH_QUERYPARAM = "?code="


const urlId = window.location.search.replace(SEARCH_QUERYPARAM, "");
const hasUrlId = !!urlId;

export const initialState: FormState = {
  id: {
    value: hasUrlId ? urlId : "",
    valid: ID_REGEX.test(urlId),
    dirty: hasUrlId ? true : false
  },
  email: {
    value: "",
    valid: false,
    dirty: false
  },
  valid: false,
  error: false,
  submitted: false
};

function reducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case "UPDATE_ID":
      return {
        ...state,
        id: {
          value: action.payload.value,
          valid: action.payload.valid,
          dirty: action.payload.value !== ""
        }
      };

    case "UPDATE_EMAIL":
      return {
        ...state,
        email: {
          value: action.payload.value,
          valid: action.payload.valid,
          dirty: action.payload.value !== ""
        }
      };

    case "FORM_SUCCESS":
      return {
        ...state,
        submitted: true
      };

    case "FORM_ERROR":
      return {
        ...state,
        error: true,
        submitted: true
      };

    case "FORM_RESET":
      return initialState;

    default:
      return state;
  }
}

export default reducer;
